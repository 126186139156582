<template>
  <div>
    <div class="row">
      <div class="col-8 mb-3">
        <label for="title_input" class="form-label">Título</label>
        <input
          type="text"
          class="form-control"
          v-model="formEdit.title"
          aria-describedby="title_help"
          placeholder="Título da Empresa"
        />
      </div>
      <div class="col-4 mb-3">
        <label for="cnpj" class="form-label">CNPJ</label>
        <input
          type="text"
          class="form-control"
          v-model="formEdit.cnpj"
          aria-describedby="cnpj"
          placeholder="CNPJ da Empresa"
        />
      </div>
    </div>
    <div class="col-12 mb-3">
      <label for="sub_title" class="form-label">Subtítulo</label>
      <input
        type="text"
        class="form-control"
        v-model="formEdit.sub_title"
        aria-describedby="sub_title"
      />
    </div>
    <div class="col-12 mb-3">
      <label for="title_input" class="form-label">Sobre a Empresa</label>
      <TiptapVr v-model="formEdit.conteudo"> </TiptapVr>
    </div>

    <div class="card p-2 mb-3">
      <div class="mb-3">
        <div class="row">
          <label for="image_path" class="form-label">Imagem / Logo</label>
          <img
            :src="urlImg"
            :key="formEdit.image_path"
            class="img-fluid img-thumb-200"
          />
        </div>
      </div>
      <div class="mb-3" v-if="!deleteMode">
        <input
          ref="pathimagem"
          type="file"
          class="form-control"
          id="path_input"
          accept="image/*"
          @change="sendImagem()"
        />
      </div>
    </div>

    <div class="card">
      <div class="card-header bg-secondary text-white">
        Informações de Contato
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-6 mb-3">
            <label for="endereco" class="form-label">Endereço da Empresa</label>
            <input
              type="text"
              class="form-control"
              v-model="formEdit.endereco"
              aria-describedby="endereco"
            />
          </div>
          <div class="col-6 mb-3">
            <label for="email" class="form-label">E-mail da Empresa</label>
            <input
              type="mail"
              class="form-control"
              v-model="formEdit.email"
              aria-describedby="email"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-3">
            <label for="telefone" class="form-label">Telefone da Empresa</label>
            <input
              type="text"
              class="form-control"
              v-model="formEdit.telefone"
              aria-describedby="telefone"
            />
          </div>
          <div class="col-6 mb-3">
            <label for="whatsapp" class="form-label">Whatsapp da Empresa</label>
            <input
              type="text"
              class="form-control"
              v-model="formEdit.whatsapp"
              aria-describedby="whatsapp"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-3 mb-3">
      <div class="card-header bg-secondary text-white">Social Midias</div>
      <div class="card-body">
        <div class="row">
          <div class="col-6 mb-3">
            <label for="facebook" class="form-label">Facebook da Empresa</label>
            <input
              type="text"
              class="form-control"
              v-model="formEdit.facebook"
              aria-describedby="facebook"
            />
          </div>
          <div class="col-6 mb-3">
            <label for="instagram" class="form-label"
              >Instagram da Empresa</label
            >
            <input
              type="text"
              class="form-control"
              v-model="formEdit.instagram"
              aria-describedby="instagram"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-3">
            <label for="linkedin" class="form-label">Linkedin da Empresa</label>
            <input
              type="text"
              class="form-control"
              v-model="formEdit.linkedin"
              aria-describedby="linkedin"
            />
          </div>

          <div class="col-6 mb-3">
            <label for="youtube" class="form-label">Youtube da Empresa</label>
            <input
              type="text"
              class="form-control"
              v-model="formEdit.youtube"
              aria-describedby="youtube"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-3">
            <label for="twitter" class="form-label">Twitter da Empresa</label>
            <input
              type="text"
              class="form-control"
              v-model="formEdit.twitter"
              aria-describedby="twitter"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- SEO Card Fields -->
    <!-- <div class="card mt-3 mb-3">
      <div class="card-header bg-secondary text-white">SEO Tags</div>
      <div class="card-body">
        <div class="row">
          <div class="mb-3">
            <label for="title_seo" class="form-label">Título do Site</label>
            <input
              type="text"
              class="form-control"
              v-model="formEdit.title_seo"
              aria-describedby="title_seo"
            />
          </div>

          <div class="mb-3 col-sm-12 col-md-6 col-lg-6">
            <div class="mb-3">
              <label for="description_seo" class="form-label"
                >Descrição do Site</label
              >
              <textarea
                class="form-control"
                v-model="formEdit.description_seo"
                aria-describedby="description_seo"
                aria-label="With textarea"
                style="min-height: 200px"
                maxlength="2000"
              ></textarea>
            </div>
          </div>
          <div class="mb-3 col-sm-12 col-md-6 col-lg-6">
            <div class="mb-3">
              <label for="keyword_seo" class="form-label"
                >Palavras Chaves</label
              >
              <textarea
                class="form-control"
                v-model="formEdit.keyword_seo"
                aria-describedby="keyword_seo"
                aria-label="With textarea"
                style="min-height: 200px"
                maxlength="2000"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="col-12 mb-3" v-if="!deleteMode">
      <label for="lang_input" class="form-label">{{ $t("status") }}</label>
      <select
        class="form-select"
        @change="formEdit.status = $event.target.value"
        v-model="formEdit.status"
      >
        <option value="1">Ativo</option>
        <option value="0">Inativo</option>
      </select>
    </div>
    <!-- END SEO Card Fields -->

    <div class="d-grid gap-2">
      <button
        class="btn btn-dark bg-dark bg-gradient col-6 mx-auto"
        type="button"
        v-if="!loading"
        @click="sendEdit"
      >
        {{ $t("submit") }} <i class="bi bi-save"></i>
      </button>
      <button
        class="btn btn-dark bg-dark bg-gradient col-6 mx-auto"
        type="submit"
        v-if="loading"
        disabled
      >
        <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{ $t("loading") }}
      </button>
    </div>
  </div>
</template>

<script>
import { URL_IMAGE } from "../../../../../services/Commons";
import AboutService from "../../../../../services/about.service";
import AboutHandle from "../../../../../services/handle/about.handle";
import TiptapVr from "../../../../../components/TipTapVr";
export default {
  props: [
    "dataItem",
    "actionSend",
    "ActionSendFile",
    "errorServer",
    "deleteMode",
  ],
  data: () => ({
    formEdit: {},
    urlImg: "",
    loading: false,
  }),
  mounted() {
    this.urlImg = URL_IMAGE + `sobredowimagem/${this.dataItem.id}/`;
    this.formEdit = this.dataItem;
    console.log(this.formEdit.status);
  },
  components: {
    TiptapVr,
  },
  methods: {
    sendEdit: function (event) {
      event.preventDefault();
      this.loading = true;
      AboutService.update(
        this.formEdit.id,
        AboutHandle.formToJso(this.formEdit)
      ).then(
        (result) => {
          result;
          this.loading = false;
          this.actionSend({
            msg: "O item foi alterado com Sucesso.",
            style: "success",
          });
        },
        (error) => {
          this.loading = false;
          this.errorServer(error);
        }
      );
    },
    sendImagem: function (field) {
      this.loading = true;
      if (this.$refs["pathimagem"].files[0]) {
        let formData = new FormData();
        formData.append("file", this.$refs["pathimagem"].files[0]);
        AboutService.sendFileImg(this.formEdit.id, formData).then(
          (result) => {
            this.formEdit[field] = result.data;
            this.loading = false;
            this.ActionSendFile();
          },
          (error) => {
            this.loading = false;
            this.errorServer(error);
          }
        );
      }
    },
  },
};
</script>

<style>
</style>