import axios from 'axios';
import authHeader from './auth-header';
import authHeaderFile from './auth-header-file';

import { URL_API } from './Commons';

class AboutService {

    async getAll() {
        return await axios.get(URL_API + 'sobres', { headers: authHeader() });
    }

    getOne(id) {
        return axios.get(URL_API + 'sobre/' + id, { headers: authHeader() });
    }

    update(id, sobre) {
        return axios.put(URL_API + 'sobre/' + id, sobre, { headers: authHeader() });
    }

  sendFileImg(id, formData) {
    return axios.post(URL_API + 'sobreupimagem/' + id, formData, { headers: authHeaderFile() });
  }


}

export default new AboutService();