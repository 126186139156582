class AboutHandle {

    formToJso(formData) {
        return {
            id: formData.id,
            title: formData.title,
            conteudo: formData.conteudo,
            whatsapp: formData.whatsapp,
            email: formData.email,
            telefone: formData.telefone,
            endereco: formData.endereco,
            facebook: formData.facebook,
            twitter: formData.twitter,
            linkedin: formData.linkedin,
            instagram: formData.instagram,
            youtube: formData.youtube,
            title_seo: formData.title_seo,
            keyword_seo: formData.keyword_seo,
            description_seo: formData.description_seo,
            status: formData.status,
            sub_title: formData.sub_title,
            cnpj: formData.cnpj,
        };
    }

}

export default new AboutHandle();