<template>
  <div>
    <h6>
      <i class="bi bi-file-earmark-plus"></i> {{ $t("add") }}
      {{ $t("banner") }} x {{ $t("about") }}
    </h6>
    <div class="table-responsive">
      <table class="table table-sm table-striped table-hover align-middle">
        <thead class="table-dark table-bordered">
          <tr>
            <th class="text-center">{{ $t("id") }}</th>
            <th>{{ $t("title") }}</th>
            <th>{{ $t("lang") }}</th>
            <th>{{ $t("image") }}</th>
            <th class="text-center">{{ $t("action") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in listBannerNew" :key="item.id">
            <td class="text-center">{{ item.id }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.lang }}</td>
            <td>
              <img
                :key="item.path"
                :src="urlImg + item.id"
                class="img-fluid img-thumb-100"
              />
            </td>
            <td class="text-center">
              <button
                @click="include(item)"
                type="button"
                class="btn btn-sm btn-success"
              >
                <i class="bi bi-file-earmark-plus"></i> {{ $t("add") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <hr class="dropdown-divider" />

    <h6>
      <i class="bi bi-file-earmark-x"></i> {{ $t("remove") }}
      {{ $t("banner") }} x {{ $t("about") }}
    </h6>
    <div class="table-responsive">
      <table class="table table-sm table-striped table-hover align-middle">
        <thead class="table-dark table-bordered">
          <tr>
            <th class="text-center">{{ $t("id") }}</th>
            <th>{{ $t("title") }}</th>
             <th>{{ $t("lang") }}</th>
            <th>{{ $t("image") }}</th>
            <th class="text-center">{{ $t("action") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in formEdit.bannerList" :key="item.id">
            <td class="text-center">{{ item.id }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.lang }}</td>
            <td>
              <img
                :key="item.path"
                :src="urlImg + item.id"
                class="img-fluid img-thumb-100"
              />
            </td>
            <td class="text-center">
              <button
                @click="remove(item)"
                type="button"
                class="btn btn-sm btn-danger"
              >
                <i class="bi bi-file-earmark-x"></i> {{ $t("remove") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { URL_BASE } from "../../../../../services/Commons";
import AboutService from "../../../../../services/about.service";
export default {
  props: [
    "dataItem",
    "errorServer",
    "deleteMode",
  ],
  data: () => ({
    listBannerNew: [],
    formEdit: {},
    loading: false,
  }),
  mounted() {
    this.urlImg = URL_BASE + `file/banner/`;
    this.formEdit = this.dataItem;
    this.reloadData();

    console.log(this.formEdit);
    console.log(this.listBanner);
  },
  methods: {
    include: function (item) {
      console.log(this.formEdit.bannerList);
      if (!this.formEdit.bannerList) {
        this.formEdit.bannerList = [];
      }
      this.formEdit.bannerList.push({ id: item.id });
      AboutService.updateBannerList(this.formEdit.id, this.formEdit).then(
        (result) => {
          console.log(result.data);
          this.reloadData();
        }
      );
    },
    remove: function (item) {
      console.log(this.formEdit.bannerList);
      if (!this.formEdit.bannerList) {
        this.formEdit.bannerList = [];
      }
      let bannerosWithoutRemover = this.formEdit.bannerList.filter(function (
        value
      ) {
        return value.id != item.id;
      });
      this.formEdit.bannerList = bannerosWithoutRemover;
      AboutService.updateBannerList(this.formEdit.id, this.formEdit).then(
        (result) => {
          console.log(result.data);
          this.reloadData();
        }
      );
    },
    reloadData() {
      this.listBannerNew = [];
      AboutService.getOne(this.formEdit.id).then((result) => {
        this.formEdit = result.data;
      });
    },
  },
};
</script>

<style>
</style>